<template>
  <custom-card class="relative">
    <select-language
      v-if="formDisplayingType === FORM_DISPLAYING_TYPE.page"
      class="!absolute top-4 md:top-2 right-4 md:right-2"
    />
    <div class="flex flex-col items-center">
      <h2 class="text-blue-700 mt-4 mb-8">
        {{ t('auth.description.label') }}
      </h2>
      <div class="flex items-start flex-col">
        <h4 class="mb-4 font-normal">
          {{ t('auth.forgotPassword.label') }}
        </h4>
        <p class="mb-6">
          {{ t('auth.forgotPassword.description') }}
        </p>
      </div>
    </div>
    <form @submit="recoverPassword">
      <auth-form-username-input
        v-model="email"
        :disabled="isFetching"
        username-type="email"
        :errors="backendValidationErrors"
      />
      <custom-button-with-spinner
        class="w-full mt-4 font-semibold"
        type="recoverPassword"
        variant="filled"
        :disabled="isFetching"
        :display-spinner="isFetching"
      >
        {{ $t('auth.forgotPassword.send') }}
      </custom-button-with-spinner>
    </form>
    <nuxt-link
      class="flex items-center justify-center gap-2 mt-6 text-blue-700 cursor-pointer"
      @click="onLinkClick"
    >
      <font-awesome-icon
        icon="chevron-left"
        size="sm"
      />
      <p>
        {{ $t('auth.login.label') }}
      </p>
    </nuxt-link>
  </custom-card>
</template>
<script setup lang="ts">
import CustomCard from '~/ui/cards/CustomCard.vue'
import AuthFormUsernameInput from '~/modules/auth/components/AuthFormUsernameInput.vue'
import CustomButtonWithSpinner from '~/ui/buttons/CustomButtonWithSpinner.vue'
import { AuthService } from '~/modules/auth/service/AuthService'
import { COLOR } from '~/ui/constants/color'
import SentryLogger from '~/services/sentry/SentryLogger'
import { FormDisplayingType } from '~/modules/auth/types/Auth.formDisplayingType'
import { FORM_DISPLAYING_TYPE } from '~/modules/auth/constants/Auth.formDisplayingType'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import SelectLanguage from '~/modules/app-language/components/SelectLanguage.vue'

const { t } = useI18n()

const props = defineProps({
  formDisplayingType: {
    type: String as PropType<FormDisplayingType>,
    default: FORM_DISPLAYING_TYPE.page,
  },
})
const emits = defineEmits<{(e: 'goToLogin'): void}>()

const authService = new AuthService()

const isFetching = ref(false)

const email = ref(null)

const backendValidationErrors = ref([])

const { handleSubmit } = useForm()

const onLinkClick = () => {
  if (props.formDisplayingType === FORM_DISPLAYING_TYPE.modal) {
    emits('goToLogin')
  } else {
    useRouter().replace({ name: ROUTES_NAMES.login })
  }
}
const recoverPassword = handleSubmit(() => {
  isFetching.value = true
  authService.recoverPassword(email.value as string)
    .then(response => {
      toast(t('auth.forgotPassword.toast.label.success', { value: email.value }), { variant: COLOR.success })
      navigateTo('login')
    })
    .catch(response => {
      SentryLogger.captureScopeException(response)
      if (response.status === AuthService.ERROR_STATUS) {
        backendValidationErrors.value = [t('auth.backendValidation.error')]
        toast(t('auth.forgotPassword.toast.label.error'), { variant: COLOR.danger })
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})

</script>
